import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

function isEn() {
  return window.location.pathname.startsWith('/en');
}

const photoswipeOptions = {
  dataSource: [
    {
      src: "/img/chaty_alt_large.jpg",
      width: 1920,
      height: 1280,
      alt: "Pohlad na vsetky 3 chaty",
    },
    {
      src: "/img/obyvacka_vyhlad_large.jpg",
      width: 1915,
      height: 1277,
      alt: "Obyvacka s vyhladom",
    },
    {
      src: "/img/stolovanie_large.jpg",
      width: 1747,
      height: 1164,
      alt: "Jedalensky stol",
    },
    {
      src: "/img/izba1_1_large.jpg",
      width: 1899,
      height: 1266,
      alt: "Hlavna spalna",
    },
    {
      src: "/img/lakes_large.jpg",
      width: 2048,
      height: 1152,
      alt: "Obyvacia izba",
    },
    {
      html: `<div class="centered-content align--center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; align-items: center;"><a class="link" href="/${isEn() ? "en/" : ""}gallery"><div class="centered-content align--center bg--white border--tan text--primary-color" style="width: 80%; height: 80%;"><h2 style="margin-bottom: 0;">${isEn() ? "Show gallery" : "Zobraz celú galériu"}</h2></a></div>`,
    },
  ],
  showHideAnimationType: "none",
  pswpModule: () => import("photoswipe"),
};

const lightbox = new PhotoSwipeLightbox(photoswipeOptions);
lightbox.init();

window.openImage = function (index) {
  gtag("event", "openImageHome", {
    index,
  });
  lightbox.loadAndOpen(index);
};

!(function () {
  window;
  const e = document.documentElement;
  if (
    (e.classList.remove("no-js"),
    e.classList.add("js"),
    document.body.classList.contains("has-animations"))
  ) {
    (window.sr = ScrollReveal()).reveal(".reveal-on-scroll", {
      duration: 600,
      distance: "20px",
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      origin: "bottom",
      interval: 100,
    });
  }
})();
